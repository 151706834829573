import { Button, Checkbox, Combobox, FormField, Input } from '@forge/common'
import {
  ColTypeEnum,
  FieldCategoryPartsFragment,
  FieldMapValue,
  FieldPartsFragment,
  ResourceGroupRole
} from '@forge/graphql/generated'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { useUpdateField } from './useUpdateField'
import {
  agentAliasOptions,
  agentDataDictionaryOptions,
  listingAliasOptions,
  listingDataDictionaryOptions,
  officeAliasOptions,
  officeDataDictionaryOptions
} from './utils'

const schema = yup.object({
  fieldCategoryId: yup.number(),
  sourceNames: yup.array(yup.string().required()),
  displayName: yup.string(),
  categoryName: yup.string(),
  subcategoryName: yup.string(),
  colName: yup.string(),
  colType: yup.mixed<ColTypeEnum>().oneOf(Object.values(ColTypeEnum)),
  aliases: yup.array(yup.string().required()),
  mapValues: yup.mixed<FieldMapValue>().oneOf(Object.values(FieldMapValue)),
  standardName: yup.string(),
  queryable: yup.bool(),
  agentOnly: yup.bool(),
  autocomplete: yup.bool(),
  compose: yup.bool(),
  sqft: yup.bool(),
  calculateDom: yup.bool()
})

export interface EditFieldProps {
  role: ResourceGroupRole
  field: FieldPartsFragment
  fieldCategories: FieldCategoryPartsFragment[]
}

export function EditField({ role, field, fieldCategories }: EditFieldProps) {
  const isListing = role === ResourceGroupRole.Listing
  const categoryOptions = fieldCategories.map((category) => ({
    label: category.name || 'NO_LABEL',
    value: category.id || 'NO_VALUE'
  }))
  const columnTypeOptions = Object.values(ColTypeEnum).map((option) => ({
    label: option,
    value: option
  }))
  const aliasOptions = (
    role === ResourceGroupRole.Listing
      ? listingAliasOptions
      : role === ResourceGroupRole.Office
      ? officeAliasOptions
      : role === ResourceGroupRole.Agent
      ? agentAliasOptions
      : []
  ).map((option) => ({
    label: option,
    value: option
  }))
  const mapValueOptions = Object.values(FieldMapValue).map((value) => ({
    label: value,
    value: value
  }))
  const standardNameOptions = (
    role === ResourceGroupRole.Listing
      ? listingDataDictionaryOptions
      : role === ResourceGroupRole.Office
      ? officeDataDictionaryOptions
      : role === ResourceGroupRole.Agent
      ? agentDataDictionaryOptions
      : []
  ).map((option) => ({
    label: option,
    value: option
  }))

  const {
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { errors }
  } = useForm<yup.InferType<typeof schema>>({
    resolver: yupResolver(schema),
    defaultValues: {
      fieldCategoryId:
        field.pendingChanges?.fieldCategoryId ||
        field.fieldCategoryId ||
        undefined,
      sourceNames: field.pendingChanges?.sourceNames || field.sourceNames || [],
      displayName:
        field.pendingChanges?.displayName || field.displayName || undefined,
      categoryName:
        field.pendingChanges?.categoryName || field.categoryName || undefined,
      subcategoryName:
        field.pendingChanges?.subcategoryName ||
        field.subcategoryName ||
        undefined,
      colName: field.pendingChanges?.colName || field.colName || undefined,
      colType: field.pendingChanges?.colType || field.colType || undefined,
      aliases: field.pendingChanges?.aliases || field.aliases || [],
      mapValues:
        field.pendingChanges?.mapValues || field.mapValues || undefined,
      standardName:
        field.pendingChanges?.standardName || field.standardName || undefined,
      queryable:
        field.pendingChanges?.queryable || field.queryable || undefined,
      agentOnly:
        field.pendingChanges?.agentOnly || field.agentOnly || undefined,
      autocomplete:
        field.pendingChanges?.autocomplete || field.autocomplete || undefined,
      compose: field.pendingChanges?.compose || field.compose || undefined,
      sqft: field.pendingChanges?.sqft || field.sqft || undefined,
      calculateDom:
        field.pendingChanges?.calculateDom || field.calculateDom || undefined
    }
  })
  const [hasUpdated, setHasUpdated] = useState(false)

  const possibleSources = useMemo(() => {
    const possibleSources = field.possileSources || []
    const possibleSourcesByValue = possibleSources.reduce<
      Record<string, { label?: string | null; value?: string | null }>
    >((byValue, option) => ({ ...byValue, [option.value || '']: option }), {})

    return Array.from(
      new Set([
        ...Object.keys(possibleSourcesByValue),
        ...(field.sourceNames || []),
        ...(field.pendingChanges?.sourceNames || [])
      ])
    ).map((value) => {
      const option = possibleSourcesByValue[value] || { label: value, value }
      return {
        label: option.label || '__NO_LABEL__',
        value: option.value || '__NO_VALUE__'
      }
    })
  }, [
    field.pendingChanges?.sourceNames,
    field.possileSources,
    field.sourceNames
  ])

  const [sourceNameOptions, setSourceNameOptions] = useState(possibleSources)

  const {
    mutateAsync: updateField,
    isLoading: isUpdating,
    error: updateError
  } = useUpdateField()

  useEffect(() => {
    const timeout = setTimeout(() => {
      setHasUpdated(false)
    }, 3000)

    return () => {
      clearTimeout(timeout)
    }
  }, [hasUpdated])

  const sourceNameOptionsMap = sourceNameOptions.reduce<
    Record<string, { label: string; value: string }>
  >((byValue, option) => ({ ...byValue, [option.value]: option }), {})

  return (
    <>
      <form
        className="space-y-4"
        onSubmit={handleSubmit(async (data) => {
          await updateField({ id: field.id, ...data })
          setHasUpdated(true)
        })}>
        <FormField
          label="Field Category"
          error={errors.fieldCategoryId?.message}>
          {(props) => (
            <Combobox
              {...props}
              selected={categoryOptions.find(
                (option) => Number(option.value) === watch('fieldCategoryId')
              )}
              options={categoryOptions}
              onSelect={(option) => {
                setValue('fieldCategoryId', Number(option.value))
              }}
            />
          )}
        </FormField>

        <FormField
          label="Source Names"
          error={errors.sourceNames?.map?.((value) => value?.message)}>
          <Combobox
            multiple
            selected={
              watch('sourceNames')?.map(
                (sourceName) => sourceNameOptionsMap[sourceName]
              ) || []
            }
            options={sourceNameOptions.sort((a, b) =>
              a.label.localeCompare(b.label)
            )}
            onSelect={(options) => {
              setValue(
                'sourceNames',
                options.map((option) => option.value)
              )
            }}
            onCreateOption={(newOption) => {
              setSourceNameOptions((options) => [...options, newOption])
            }}
          />
        </FormField>

        <FormField label="Display Name" error={errors?.displayName?.message}>
          <Input {...register('displayName')} />
        </FormField>

        <FormField label="Category Name" error={errors.categoryName?.message}>
          <Input {...register('categoryName')} />
        </FormField>

        <FormField
          label="Subcategory Name"
          error={errors.subcategoryName?.message}>
          <Input {...register('subcategoryName')} />
        </FormField>

        <FormField label="Column Name" error={errors.colName?.message}>
          <Input {...register('colName')} />
        </FormField>

        <FormField label="Column Type" error={errors.colType?.message}>
          <Combobox
            selected={columnTypeOptions.find(
              (option) => option.value === watch('colType')
            )}
            options={columnTypeOptions}
            onSelect={(option) => {
              setValue('colType', option.value as ColTypeEnum)
            }}
          />
        </FormField>
        <FormField
          label="Aliases"
          error={errors?.aliases?.map?.((value) => value?.message)}>
          <Combobox
            multiple
            selected={aliasOptions.filter((option) =>
              watch('aliases')?.includes(option.value)
            )}
            options={aliasOptions}
            onSelect={(options) => {
              setValue(
                'aliases',
                options.map((option) => option.value)
              )
            }}
          />
        </FormField>
        <FormField
          label="Field Mapping Settings"
          error={errors.mapValues?.message}>
          <Combobox
            selected={mapValueOptions.find(
              (option) => option.value === watch('mapValues')
            )}
            options={mapValueOptions}
            onSelect={(option) => {
              setValue('mapValues', option.value as FieldMapValue)
            }}
          />
        </FormField>
        <FormField
          label="Data Dictionary Name"
          error={errors.standardName?.message}>
          <Combobox
            selected={standardNameOptions.find(
              (option) => option.value === watch('standardName')
            )}
            options={standardNameOptions}
            onSelect={(option) => {
              setValue('standardName', option.value)
            }}
          />
        </FormField>
        <div className="space-y-4">
          <h3 className="text-xl">Options</h3>
          <Checkbox
            size="lg"
            {...register('queryable')}
            onChange={(e) => setValue('queryable', e.target.checked)}>
            Queryable
            <small className="block">
              Check this box if this field needs to be searchable via the API
            </small>
          </Checkbox>
          {isListing && (
            <Checkbox
              size="lg"
              {...register('agentOnly')}
              onChange={(e) => setValue('agentOnly', e.target.checked)}>
              Agent Only
              <small className="block">
                Check this box if this field is for an agent-only feature field
                (i.e. Lockbox Info)
              </small>
            </Checkbox>
          )}
          <Checkbox
            size="lg"
            {...register('autocomplete')}
            onChange={(e) => setValue('autocomplete', e.target.checked)}>
            Autocomplete
            <small className="block">
              Check this box if this field needs to support autocomplete
              functions
            </small>
          </Checkbox>
          <Checkbox
            size="lg"
            {...register('compose')}
            onChange={(e) => setValue('compose', e.target.checked)}>
            Composite
            <small className="block">
              Check this box if this field's values should have{' '}
              <span className="font-semibold text-gray-900 text-mono">
                source_name
              </span>{' '}
              appended to them{' '}
            </small>
          </Checkbox>
          {isListing && (
            <>
              <Checkbox
                size="lg"
                {...register('sqft')}
                onChange={(e) => setValue('sqft', e.target.checked)}>
                Squarefoot
                <small className="block">
                  Check this box if this field is associated with square feet
                </small>
              </Checkbox>
              <Checkbox
                size="lg"
                {...register('calculateDom')}
                onChange={(e) => setValue('calculateDom', e.target.checked)}>
                Calculate DOM
                <small className="block">
                  Check this box if this field is to be calculated in
                  elasticsearch as the days on market
                </small>
              </Checkbox>
            </>
          )}
        </div>
        <Button loading={isUpdating}>Update Field</Button>
        {updateError && (
          <p role="alert" aria-live="polite" className="text-sm text-red-500">
            {updateError.message}
          </p>
        )}
        {hasUpdated && (
          <p role="alert" aria-live="polite" className="text-sm text-green-500">
            Field updated!
          </p>
        )}
      </form>
    </>
  )
}
